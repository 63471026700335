.Paper{
    padding: 30px
}

.Center {
    margin: auto auto;
}

.DetailPanel{
    margin: 20px;
    padding: 30px;
    background-color: #ffffff;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.Title{
    margin-bottom: 0px;
}

.DateColor{
    color:#77909f;
}

.Button{
    margin: 5px;
}

.CopyButton{    
  color: rgb(17, 31, 58);
}
.CopyButton:hover{    
  color: rgb(58, 79, 119);
  cursor: pointer;
}

h1,h5,h4, h3{
    margin-top:0;
}

.Key{
    color: rgb(87, 89, 90);
}

h3{
    margin-bottom: 0;
}

.Image{
    width: 100%;
    height: 100%;
}