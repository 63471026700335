.NavLink{
    text-decoration: none;
    color: rgb(189, 189, 189);
}

.Active> div{    
    cursor: pointer;
    background: rgb(22, 22, 22);
    color: rgb(252, 252, 252);
    transition-duration: 0.2s;
}
