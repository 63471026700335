.Backdrop {
    position: fixed;
    visibility: hidden;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(51, 55, 58, 0.9);
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-out;
}

.Show {
    visibility: visible;
}