.padding{
    padding: 1rem;
}

.margin{
    margin: 2rem;
}

.card{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}