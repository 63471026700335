.Paper{
    padding: 30px
}

.Center {
    margin: auto auto;
}

.DetailPanel{
    margin: 20px;
    padding: 30px;
    background-color: #f6f3f3;
}

.Title{
    margin-bottom: 0px;
}

.DateColor{
    color:#77909f;
}

.Button{
    margin: 5px;
}
