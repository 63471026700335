
// Colors
$bg-color: rgb(31, 31, 31);
$dot-color: rgb(102, 102, 102);

// Dimensions
$dot-size: 2px;
$dot-space: 22px;

.Background {
	background:
		linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
		linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
		$dot-color;
	background-size: $dot-space $dot-space;
}
.Login {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}
